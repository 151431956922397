<template>
  <div class="app">
   <comhd></comhd>
    <div class="main">
      <md-scroll-view
          :scrolling-x="false"
          auto-reflow
      >
        <md-tabs>
          <md-tab-pane class="content" name="p1" label="个人志愿者">
            <md-field>
              <md-input-item title="真实姓名" v-model="single.realname" placeholder="输入真实姓名" />
              <md-input-item title="手机号码" v-model="single.phone"  placeholder="输入手机号码" />
              <md-input-item title="QQ号码" v-model="single.qq" placeholder="输入QQ号码" />
              <md-input-item title="特长" v-model="single.goodat" placeholder="输入专业特长" />
              <md-field-item title="志愿时间">
              </md-field-item>
              <div>
                <md-radio name="工作日" v-model="single.servertime" label="工作日" inline />
                <md-radio name="法定时间" v-model="single.servertime" label="法定时间" inline />
                <md-radio name="不限" v-model="single.servertime" label="不限" inline />
                <md-radio name="其他" v-model="single.servertime" label="其他" inline />
              </div>
              <md-field-item title="志愿意向" />
              <div>
                <md-check-group v-model="single.serverdir">
                  <md-check-box name="培训辅导">培训辅导</md-check-box>
                  <md-check-box name="活动演出">活动演出</md-check-box>
                  <md-check-box name="布展/讲解/导览">布展/讲解/导览</md-check-box>
                  <md-check-box name="非遗保护">非遗保护</md-check-box>
                </md-check-group>
              </div>

              <md-textarea-item
                  ref="demo0"
                  title="志愿者经历"
                  class="example"
                  :autosize="true"
                  v-model="single.desc"
                  :rows="1"
                  placeholder="填写您的志愿者经历"
                  :max-height="150"
              />
              <md-button @click="regsingle">提交</md-button>
            </md-field>
          </md-tab-pane>
          <md-tab-pane class="content" name="p2" label="团队注册者">
            <md-field>
              <md-input-item title="团队名称" v-model="tuan.teamname" placeholder="输入团队名称" />
              <md-input-item title="团队人数" v-model="tuan.peoples" placeholder="输入团队人数" />
              <md-input-item title="负责人" v-model="tuan.realname" placeholder="输入负责人姓名" />
              <md-input-item title="手机号码" v-model="tuan.phone"  placeholder="输入手机号码" />
              <md-input-item title="QQ号码" v-model="tuan.qq" placeholder="输入QQ号码" />
              <md-input-item title="特长" v-model="tuan.goodat" placeholder="输入专业特长" />
              <md-field-item title="志愿时间">
              </md-field-item>
              <div>
                <md-radio name="工作日" v-model="tuan.servertime" label="工作日" inline />
                <md-radio name="法定时间" v-model="tuan.servertime" label="法定时间" inline />
                <md-radio name="不限" v-model="tuan.servertime" label="不限" inline />
                <md-radio name="其他" v-model="tuan.servertime" label="其他" inline />
              </div>
              <md-field-item title="志愿意向" />
              <div>
                <md-check-group v-model="tuan.serverdir">
                  <md-check-box name="培训辅导">培训辅导</md-check-box>
                  <md-check-box name="活动演出">活动演出</md-check-box>
                  <md-check-box name="布展/讲解/导览">布展/讲解/导览</md-check-box>
                  <md-check-box name="非遗保护">非遗保护</md-check-box>
                </md-check-group>
              </div>

              <md-textarea-item
                  ref="demo0"
                  title="志愿者经历"
                  class="example"
                  :autosize="true"
                  v-model="single.desc"
                  :rows="1"
                  placeholder="填写您的志愿者经历"
                  :max-height="150"
              />
              <md-button @click="regteam">提交</md-button>
            </md-field>
          </md-tab-pane>

        </md-tabs>
      </md-scroll-view>
    </div>


  </div>



</template>

<script>
export default {
name: "List",
  data(){
    return {
        single:{
          desc:'',
          serverdir:[],
          servertime:'',
          goodat:'',
          qq:'',
          phone:'',
          realname:''
        },
        tuan:{
          desc:'',
          teamname:'',
          peoples:'',
          serverdir:[],
          servertime:'',
          goodat:'',
          qq:'',
          phone:'',
          realname:''
        }
    }
  },
  mounted() {
       const  uid  = this.$store.state.userInfo.uid;
       if(!uid) {
          this.$toast.info('请先登录');
          setTimeout(_=>this.$router.back(),1500);
       }
  },
  methods:{
    async regsingle(){
      const  uid  = this.$store.state.userInfo.uid;

      if(!uid) {
        this.$toast.info('请先登录');
        setTimeout(_=>this.$router.back(),1500);
        return
      }
      //数据过滤
      if(!this.single.realname)return this.$toast.failed('请填写真实姓名！')
      if(!this.single.phone)return this.$toast.failed('请填写手机号！')
      if(!this.single.qq)return this.$toast.failed('请填写qq！')
      if(!this.single.servertime)return this.$toast.failed('请填写志愿时间！')
      if(!this.single.serverdir)return this.$toast.failed('请填写志愿意向！')
      if(!this.single.desc)return this.$toast.failed('请填写志愿经历！')
      const zhengze = /^1[3456789]\d{9}$/
      if(!zhengze.test(this.single.phone)) return this.$toast.failed('请填写正确的手机号码！')

      let res = await this.$api.post('/mapi/user/single',{uid,...this.single,serverdir:this.single.serverdir.join()})
      const {success,info} = res.data;
      if(!success) return this.$toast.info(info)
      this.$toast.succeed('提交成功，请等待后台审核')
    },
    async regteam(){
     //数据过滤
      let uid = this.$store.state.userInfo.uid
      if(!this.tuan.teamname)return this.$toast.failed('请填写团队名称！')
      if(!this.tuan.peoples)return this.$toast.failed('请填写团队人数！')
      if(!this.tuan.realname)return this.$toast.failed('请填写负责人姓名！')
      if(!this.tuan.phone)return this.$toast.failed('请填写手机号！')
      if(!this.tuan.qq)return this.$toast.failed('请填写qq！')
      if(!this.tuan.servertime)return this.$toast.failed('请填写志愿时间！')
      if(!this.tuan.serverdir)return this.$toast.failed('请填写志愿意向！')
      if(!this.tuan.desc)return  this.$toast.failed('请填写志愿经历！')

      //手机号正则
      const zhengze = /^1[3456789]\d{9}$/
      if(!zhengze.test(this.single.phone)) return this.$toast.failed('请填写正确的手机号码！')

      let res = await this.$api.post('/mapi/user/team',{uid,...this.tuan,serverdir:this.tuan .serverdir.join()})
      const {success,info} = res.data;
      if(!success) return this.$toast.info(info)
      this.$toast.succeed('提交成功，请等待后台审核')
    }
  }


}
</script>

<style lang="scss" scoped>
.main{
  height: 0;
  flex-grow: 1;
}
::v-deep .md-check-box{
  margin-left: 10px;
  margin-top: 10px;
}
</style>
